import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { PlayerOptions } from '@src/js/types/Player'
import { ApiV4Client } from '@src/js/ApiClient'
import { ThunkAPI } from '@src/js/redux/store'

export enum PlayerError {
  NO_SLUG_OR_STREAM_TYPE = 'no-slug-or-stream-type',
  NO_PLAYER_OPTIONS = 'no-player-options',
  NO_SOURCE = 'no-source',
  VIDEO_JS = 'video-js',
  GEOBLOCKED = 'geoblocked',
  GENERIC = 'generic',
}

export type StreamTypes =
  | 'films' | 'trailers' | 'making_ofs' | 'clips' | 'interviews'
  | 'promo_clips' | 'education_exclusives'

interface StreamData {
  hls: string
  hls_dv: string | undefined
}

export interface StreamFetchResponse {
  registryId: number
  slug: string,
  streamType: StreamTypes
  source: string | undefined
  dvSource: string | undefined
}

// We need this 3 infos to identify player
// Extras work have the same registry id as the main work
export interface PlayerUniqId {
  registryId: number,
  slug: string,
  streamType: StreamTypes
}

export interface PlayerOptionsList {
  [key: string]: PlayerOptions
}

export interface PlayersState {
  // Player options retrieve with window.PLAYER_OPTIONS
  playerOptionsList: PlayerOptionsList|null,
  queue: number[],
  currentPlaying: PlayerUniqId|null,
  currentError: PlayerError|null,
  isLoading: boolean,
  nextIdReturnedByAPI: number|null
}

const initialState: PlayersState = {
  playerOptionsList: null,
  queue: [],
  currentPlaying: null,
  currentError: null,
  isLoading: true,
  nextIdReturnedByAPI: null
}

const apiV4Client = ApiV4Client.instance

export const fetchStream = createAsyncThunk<
  StreamFetchResponse, PlayerUniqId, ThunkAPI
>('players/fetchStream', async ({ registryId, slug, streamType }) => {
  let streamData: StreamData | null = null
  const streamResponse: AxiosResponse = await apiV4Client.call({
    method: 'GET',
    url: `${streamType}/${slug}/stream`
  })

  if (streamResponse.status === 200) {
    streamData = await streamResponse.data
  }

  return {
    registryId,
    slug,
    streamType,
    source: streamData?.hls,
    dvSource: streamData?.hls_dv
  }
})

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    addToPlayerOptionsList (state: PlayersState, action: PayloadAction<PlayerOptionsList|null>) {
      state.playerOptionsList = {
        ...(state.playerOptionsList ?? {}),
        ...action.payload
      }
    },
    setQueue (state: PlayersState, action: PayloadAction<number[]>) {
      state.queue = action.payload
    },
    setCurrentPlaying (state: PlayersState, action: PayloadAction<PlayerUniqId|null>) {
      state.currentPlaying = action.payload
    },
    setCurrentError (state: PlayersState, action: PayloadAction<PlayerError|null>) {
      state.currentError = action.payload
    },
    setIsLoading (state: PlayersState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setNextIdReturnedByAPI (state: PlayersState, action: PayloadAction<number|null>) {
      state.nextIdReturnedByAPI = action.payload
    },
    // Modify player options: currently only used in Cypress tests
    modifyPlayerOptions (state, action: PayloadAction<Record<number, PlayerOptions>>) {
      const id = parseInt(Object.keys(action.payload)[0])
      const existingOptions = state.playerOptionsList && id in state.playerOptionsList
        ? state.playerOptionsList[id]
        : {}
      const options = {
        ...existingOptions,
        ...action.payload[id]
      }
      state.playerOptionsList = {
        ...(state.playerOptionsList ?? {}),
        [id]: options
      }
    },
    // Invalidate stream data: currently only used in Cypress tests
    invalidateStreamDataForWork (state, action: PayloadAction<number>) {
      const registryId = action.payload
      if (state.playerOptionsList && registryId in state.playerOptionsList) {
        state.playerOptionsList[registryId] = {
          ...state.playerOptionsList[registryId],
          source: '',
          dvSource: ''
        }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStream.fulfilled, (state: PlayersState, action: PayloadAction<StreamFetchResponse>) => {
        const { registryId, source, dvSource } = action.payload

        // Add source to player options
        if (source && state.playerOptionsList && state.playerOptionsList[registryId]) {
          state.playerOptionsList[registryId] = {
            ...state.playerOptionsList[registryId],
            source,
            dvSource
          }
        }
      })
  },
  selectors: {
    selectPlayerOptionsByRegistryId (state: PlayersState, registryId: number) {
      if (!state.playerOptionsList) { return null }

      return state.playerOptionsList[registryId]
    },
    selectNextInQueue: (state: PlayersState, currentRegistryId: number) => {
      if (state.nextIdReturnedByAPI) {
        return state.nextIdReturnedByAPI
      }

      let next
      const indexOfCurrent = state.queue.indexOf(currentRegistryId)
      const indexOfNext = indexOfCurrent + 1
      if (indexOfNext > state.queue.length - 1) {
        next = state.queue[0]
      } else {
        next = state.queue[indexOfNext]
      }
      return next
    }
  }
})

export const {
  selectPlayerOptionsByRegistryId,
  selectNextInQueue
} = playersSlice.getSelectors()

export const {
  addToPlayerOptionsList,
  setQueue,
  setCurrentPlaying,
  setCurrentError,
  setIsLoading,
  setNextIdReturnedByAPI,
  modifyPlayerOptions,
  invalidateStreamDataForWork
} = playersSlice.actions

export default playersSlice.reducer
