import { PayloadAction } from '@reduxjs/toolkit'

import rootStore from '@web-nfb/workshop-frontend/src/store'
import { FilmHistory, updateFilmHistoryList } from '@src/js/redux/filmHistoryReducer'

const historyMiddleware = (store: typeof rootStore) => (next: (action: PayloadAction<FilmHistory>) => void) => async (action: PayloadAction<FilmHistory>) => {
  if (action.type.match(/workHistory\/post\/fulfilled/)) {
    // Before saving to work history, we want to save item in film history
    store.dispatch(updateFilmHistoryList([action.payload]))
  }
  next(action)
}

export default historyMiddleware
