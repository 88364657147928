import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ApiV4Client } from '@src/js/ApiClient'
import { ThunkAPI } from '@src/js/redux/store'
import { fetchByRegistryIds, basicIncludeFields } from '@src/js/redux/worksReducer'

const apiV4Client = ApiV4Client.instance

export interface ChannelsState {
  list: number[] | null
  status: undefined | 'loading' | 'succeeded' | 'failed'
}

const initialState: ChannelsState = {
  list: null,
  status: undefined
}

export const fetchChannel = createAsyncThunk<
number[], string, ThunkAPI
>('channelsPage/fetchChannel', async (slug, thunkAPI) => {
  const response = await apiV4Client.call({
    method: 'GET',
    url: `/channels/${slug}/items`,
    params: {
      response_format: 'registry_id',
      types: 'film,series,collection,interactive',
      per_page: 100
    }
  })

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  const idsList = await response.data.data.map(r => r.registry_id)
  await thunkAPI.dispatch(fetchByRegistryIds({ idList: idsList, includeFields: basicIncludeFields, collection: 'gc/gc-films' }))

  return idsList as number[]
})

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchChannel.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchChannel.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.list = action.payload ?? []
      })
      .addCase(fetchChannel.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
})

export default channelsSlice.reducer
