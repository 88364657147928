import { LitElement, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'

// @ts-expect-error Lit Sass import
import styles from '@src/sass/wc/NFBWorkPageCreator.sass?lit'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

import normanPaths from '@src/js/helpers/paths'

export interface Creator {
  first_name: string
  last_name: string
  slug: string
}

@customElement('nfb-work-page-creator')
export default class NFBWorkPageCreator extends LitElement {
  @property({ type: Array })
  creators: Creator[] | null = null

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-unavailable' })
  isUnavailable = false

  @property({ type: String, attribute: 'variant' })
  variant: 'header' | null = null

  static styles = [styles]

  renderDirectors () {
    return this.creators?.map((c, index) => {
      let suffix = ''
      if (this.creators!.length > 1 && index !== this.creators!.length - 1) {
        suffix = index === this.creators!.length - 2 ? window.gettext('&') : ', '
      }

      return html`
        <span class="director" itemscope itemtype="http://schema.org/Person" data-cy="director">
          <a 
            class="nfb-work-page-creator__link ${this.isUnavailable ? 'nfb-work-page-creator__link--inactive' : ''}"
            href="${normanPaths.directors.replace('{slug}', c.slug)}"
            title="${window.gettext('More films by')} ${c.first_name} ${c.last_name}"
            itemprop="url"
            target="_parent"
          >
            <span itemprop="director" >${c.first_name} ${c.last_name}</span>
          </a>
          <span class="nfb-work-page-creator__separator">
            ${suffix}
          </span>
        </span>
      `
    })
  }

  render () {
    if (!this.creators) return

    return html`
      <div
        class="nfb-work-page-creator ${this.variant ? ` nfb-work-page-creator--${this.variant}` : ''} ${this.isDark ? 'nfb-work-page-creator--dark' : ''}"
      >
        ${this.renderDirectors()}
      </div>
    `
  }
}
