const normanPaths = {
  series: window.gettext('/series/{slug}/'),
  collection: window.gettext('/collection/{slug}/'),
  film: window.gettext('/film/{slug}/'),
  interactive: window.gettext('/interactive/{slug}'),
  directors: window.gettext('/directors/{slug}/'),
  embed: window.gettext('/film/{slug}/embed/player/'),
  promoEmbed: window.gettext('/film/{slug}/{streamType}/{slug}/embed/player/')
}

export default normanPaths
