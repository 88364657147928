/**
 * Class NFBDisplayWorkCard.
 *
 * Component that can display any type of work on any variant of card.
 */

import { LitElement, TemplateResult, css, nothing } from 'lit'
import { html, literal } from 'lit/static-html.js'
import { Unsubscribe } from '@reduxjs/toolkit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit-html/directives/class-map.js'
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js'

import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

import { formatDuration, formatSeriesInfo, formatCollectionInfo, formatYearRange, formatComingSoonDate } from '@src/js/helpers/format'
import normanPaths from '@src/js/helpers/paths'
import { getAvailabilityStatusForWork, WorkAvailabilityStatus } from '@src/js/helpers/availability'
import { store, RootState } from '@src/js/redux/store'
import { WorkCard, selectWorkById } from '@src/js/redux/worksReducer'

// @ts-expect-error Lit Sass import
import cardStyleSlot from '@web-nfb/frontend-static/design-system/wc/card/NFBCardSlot.sass?lit'
import { escapeText } from '@web-nfb/frontend-static/design-system/script/utils/string'
import { selectActiveRentalForWork } from '@src/js/redux/userReducer'
import { getPlatformLanguageCode } from '@src/js/helpers/language'
import type NFBCard from '@web-nfb/frontend-static/design-system/wc/card/NFBCard'

enum CardType {
 carousel = 'carousel',
 bookmark = 'bookmark'
}

export enum bookmarkButtonType {
  add = 'add',
  delete = 'delete',
}

@customElement('nfb-display-work-card')
export class NFBDisplayWorkCard extends LitElement {
  @property({ type: Number, attribute: 'registry-id' })
  registryId: number

  @property({ type: String, attribute: 'href' })
  href = ''

  @property({ type: String, attribute: 'card-type' })
  cardType: CardType | null = null

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark: boolean = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-active' })
  isActive: boolean = false

  @property({ type: String, attribute: 'gtm-attributes' })
  gtmAttribute: string = ''

  @property({ converter: stringToBooleanConverter, attribute: 'include-progress-bar' })
  includeProgressBar: boolean = false

  @property({ converter: stringToBooleanConverter, attribute: 'include-description' })
  includeDescription: boolean = false

  @property({ converter: stringToBooleanConverter, attribute: 'truncate-description' })
  truncateDescription: boolean = true

  @property({ converter: stringToBooleanConverter, attribute: 'include-see-more-link' })
  includeSeeMoreLink: boolean = false

  @property({ converter: stringToBooleanConverter, attribute: 'use-thumbnail-pattern' })
  // Set to false if thumbnailPath is an absolute URL
  // and no pattern should be used to generate thumbnail
  useThumbnailPattern: boolean = true

  @property({ type: String, attribute: 'primary-variant' })
  primaryVariant: string = ''

  @property({ type: String, attribute: 'secondary-variant' })
  secondaryVariant: string = ''

  @property({ attribute: 'bookmark-button-type' })
  bookmarkButtonType: bookmarkButtonType | null = null

  @property({ type: String, attribute: 'thumbnail-path' })
  thumbnailPath: string | null = null

  @property({ type: String, attribute: 'thumbnail-pattern' })
  thumbnailPattern: string | null = null

  @state()
  availabilityStatus: WorkAvailabilityStatus = WorkAvailabilityStatus.PUBLIC

  @state()
  work: WorkCard | null = null

  @state()
  contextWork: WorkCard | null = null

  _storeUnsubscribe: Unsubscribe
  _cardId: string
  _rootState: RootState
  _thumbnailPatterns: { [key: string]: string }

  static get styles () {
    return [cardStyleSlot, css`
      nfb-work-page-legend::part(container) {
        margin-top: 0;
        margin-bottom: 0;
      }
  
      .nfb-display-work-card--truncate-description .nfb-card__description div {
        display: -webkit-box !important;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden !important;
        word-break: normal !important;
      }
    `]
  }

  constructor () {
    super()
    this.stateChanged = this.stateChanged.bind(this)
    this.getIsInert = this.getIsInert.bind(this)
    this.getCardElement = this.getCardElement.bind(this)
  }

  async connectedCallback (): Promise<void> {
    super.connectedCallback()
    this._storeUnsubscribe = store.subscribe(this.stateChanged)
    this.stateChanged()
  }

  disconnectedCallback (): void {
    super.disconnectedCallback()
    this._storeUnsubscribe()
  }

  protected firstUpdated () {
    this._cardId = `card-${this.work?.id}`
  }

  attributeChangedCallback (name: string, _old: string | null, value: string | null): void {
    super.attributeChangedCallback(name, _old, value)
    if (name === 'registry-id' && _old !== null) {
      // When registry id changed
      this.work = null // show loading
      // Update new work
      this.updateWork()
    }
  }

  stateChanged (): void {
    this._rootState = store.getState()
    this.updateWork()
  }

  updateWork () {
    const { works } = store.getState()
    if (this.registryId) {
      this.work = selectWorkById(works, this.registryId)
      if (this.work) {
        this._thumbnailPatterns = works.thumbnailPattern
        this.availabilityStatus = getAvailabilityStatusForWork(this.work)
      }
    }
  }

  getGTMAttributes (): string {
    const jsonGTMAttribute = this.gtmAttribute
      ? JSON.parse(this.gtmAttribute)
      : {}

    return JSON.stringify({
      ...jsonGTMAttribute,
      nfb_version_title: this.work?.title
    })
  }

  getFilmLegend (work: WorkCard) {
    return html`
      ${work.directors
    ? html`<nfb-work-page-creator
        creators="${JSON.stringify(work.directors)}"
        is-unavailable="${this.cardShouldBeDisabled(this.availabilityStatus)}"
        is-dark="${this.isDark ?? nothing}"
      ></nfb-work-page-creator>`
    : nothing}
      <div class="nfb-legend">
        <nfb-work-page-legend 
          year="${work.year}"
          running-time="${formatDuration(work.duration)}"
        >
        </nfb-work-page-legend>
      </div>
    `
  }

  getSeriesOrCollectionLegend (work: WorkCard) {
    let seriesInfo = ''
    let creatorSlot = null
    if (work.category === 'series') {
      creatorSlot = html`
        <nfb-work-page-creator
          creators="${JSON.stringify(work.directors)}"
          is-unavailable="${this.cardShouldBeDisabled(this.availabilityStatus)}"
          is-dark="${this.isDark ?? nothing}"
        ></nfb-work-page-creator>`
      seriesInfo = formatSeriesInfo(work)
    } else {
      seriesInfo = formatCollectionInfo(work)
    }
    return html`
      ${creatorSlot}
      <div class="nfb-legend">
        <nfb-work-page-legend 
          year="${formatYearRange(work)}"
          series-info="${seriesInfo}"
        >
        </nfb-work-page-legend>
      </div>
    `
  }

  getInteractiveLegend (work: WorkCard) {
    return html`
      <div class="nfb-legend">
        <nfb-work-page-legend 
          year="${work.year}"
          running-time="${formatDuration(work.duration)}"
        >
        </nfb-work-page-legend>
      </div>
    `
  }

  getProgressBar (work: WorkCard) {
    if (!this.includeProgressBar) return nothing

    return html`
    <div slot="progress-bar" class="nfb-progress-bar" data-ui-active="true">
      <nfb-history-progress-bar
        full-width="true"
        registry-id="${work.id}"
      >
      </nfb-history-progress-bar>
    </div>`
  }

  getBookmarkButton (work: WorkCard) {
    if (!this.bookmarkButtonType) return nothing

    if (this.bookmarkButtonType === bookmarkButtonType.delete) {
      return html`
        <div slot="add-to-my-list">
          <nfb-delete-bookmark-button
            registry-id="${work.id}"
            source-title="${work.title}"
            is-small="true"
          >
          </nfb-delete-bookmark-button>
        </div>`
    }

    if (this.bookmarkButtonType === bookmarkButtonType.add) {
      return html`
        <div slot="add-to-my-list">
          <nfb-add-to-mylist
            registry-id="${this.registryId}"
            source-title="${work.title}"
            is-dark="${this.isDark}"
            is-small="true"
          >
          </nfb-add-to-mylist>
        <div> 
      `
    }
  }

  getDescription (work: WorkCard) {
    let description = null
    let showMoreLink = null

    if (this.includeDescription) {
      description = html`
        <div>
          ${unsafeHTML(escapeText(work.tagline ?? work.description))}
        </div>
      `
    }

    if (this.includeSeeMoreLink) {
      showMoreLink = html`
        <div class="nfb-card__extra-header-content">
          <a href="${work.availability?.resource_url}">${window.gettext('More info')}</a>
        </div>
      `
    }

    if (!description && !showMoreLink) return nothing

    return html`
      <div slot="description" class="nfb-card__description">
       ${description}
       ${showMoreLink}
      </div>
    `
  }

  getAvailabilityMessage (availabilityStatus: WorkAvailabilityStatus, work: WorkCard): TemplateResult | typeof nothing {
    if (availabilityStatus === WorkAvailabilityStatus.UNAVAILABLE) {
      const message = work.geoblocked
        ? window.gettext('Not available to view in your current location.')
        : window.gettext('This content is no longer available.')
      return html`
        <nfb-inline-message
          is-dark="${this.isDark ?? nothing}"
          text="${message}"
        >
        </nfb-inline-message>`
    } else if (availabilityStatus === WorkAvailabilityStatus.PROMO && work.coming_soon) {
      return html`<nfb-pill text="${`${window.pgettext('Coming soon date', 'Coming')} ${formatComingSoonDate(work.coming_soon)}`}"></nfb-pill>`
    }

    return nothing
  }

  getRestriction (availabilityStatus: WorkAvailabilityStatus): TemplateResult | typeof nothing {
    const workIsDTO = this.work?.availability?.buy as boolean
    const workIsRental = this.work?.availability?.rent as boolean

    if (availabilityStatus === WorkAvailabilityStatus.PURCHASE) {
      if (workIsDTO && workIsRental) {
        return html`
        <nfb-badge
          text="${window.gettext('Available to rent or buy')}"
          icon-name="cash"
          is-dark="${this.isDark ?? nothing}"
        >
        </nfb-badge>`
      } else if (workIsRental) {
        return html`
        <nfb-badge
          text="${window.gettext('Available to rent')}"
          icon-name="cash"
          is-dark="${this.isDark ?? nothing}"
        >
        </nfb-badge>`
      } else {
        return html`
        <nfb-badge
          text="${window.gettext('Available to buy')}"
          icon-name="cash"
          is-dark="${this.isDark ?? nothing}"
        >
        </nfb-badge>`
      }
    } else if (availabilityStatus === WorkAvailabilityStatus.RENTED) {
      const rental = selectActiveRentalForWork(this._rootState.user, this.work!)
      const formatedExpiredDate = (new Date(rental!.expiration_date)).toLocaleString(getPlatformLanguageCode())
      return html`
        <nfb-inline-message
          is-dark="${this.isDark ?? nothing}"
          text="${`${window.gettext('Your rental expires on')} ${formatedExpiredDate}`}"
        >
        </nfb-inline-message>`
    }

    return nothing
  }

  cardShouldBeDisabled (availabilityStatus: WorkAvailabilityStatus) {
    return availabilityStatus === WorkAvailabilityStatus.UNAVAILABLE
  }

  handleUrl (work: WorkCard) {
    if (work.category === 'series') return normanPaths.series.replace('{slug}', work.slug)

    if (work.category === 'collection') return normanPaths.collection.replace('{slug}', work.slug)

    return normanPaths.film.replace('{slug}', work.slug)
  }

  getClasses (availabilityStatus: WorkAvailabilityStatus) {
    return {
      'nfb-bookmarks--inactive': this.cardShouldBeDisabled(availabilityStatus),
      'nfb-display-work-card--truncate-description': this.truncateDescription
    }
  }

  // Implementing this function will allow NFBCarousel handleArrowButtonFocus() to fetch right card element
  getIsInert (): boolean {
    return (this.shadowRoot?.querySelector('nfb-carousel-card') as NFBCard)?.getIsInert()
  }

  getCardElement (): HTMLElement {
    return (this.shadowRoot?.querySelector('nfb-carousel-card') as NFBCard) ?? false
  }

  render () {
    if (!this.work) {
      return html`
        <nfb-spinner
          is-dark="${this.isDark}"
          is-loading="true"
        ></nfb-spinner>`
    }

    const elementTag = this.cardType === CardType.carousel
      ? literal`nfb-carousel-card`
      : literal`nfb-card`

    const thumbnailPattern = this.useThumbnailPattern
      ? (this.thumbnailPattern ?? this._thumbnailPatterns[this.work.category] ?? this._thumbnailPatterns.film)
      : nothing

    return html`
      <${elementTag} ${this.cardType === CardType.carousel ? literal`data-ui-el="carousel-card"` : nothing}
        part="nfb-card"
        data-ui-el="${this._cardId}"
        class="${classMap(this.getClasses(this.availabilityStatus))}"
        primary-variant="${this.primaryVariant ?? nothing}"
        secondary-variant="${this.secondaryVariant ?? nothing}"
        title="${this.work.title}"
        is-unavailable="${this.cardShouldBeDisabled(this.availabilityStatus)}"
        href="${this.href ? this.href : this.handleUrl(this.work)}"
        default-thumbnail="${window.FALLBACK_THUMBNAIL_PATTERN.replace('{path}', window.FALLBACK_THUMBNAIL_PATH)}"
        thumbnail-path="${this.thumbnailPath ? this.thumbnailPath : (this.work.thumbnail ? this.work.thumbnail : nothing)}"
        thumbnail-url-pattern="${thumbnailPattern}"
        gtm-attributes="${this.getGTMAttributes() ?? nothing}"
        is-dark="${this.isDark ?? nothing}"
        is-active="${this.isActive ?? nothing}"
      >
        <div slot="legend">
          ${this.getRestriction(this.availabilityStatus)}
          ${this.getAvailabilityMessage(this.availabilityStatus, this.work)}
          ${['collection', 'series'].includes(this.work.category)
    ? this.getSeriesOrCollectionLegend(this.work)
    : (this.work.category === 'interactive' ? this.getInteractiveLegend(this.work) : this.getFilmLegend(this.work))}
        </div>
        ${this.getProgressBar(this.work)}
        ${this.getBookmarkButton(this.work)}
        ${this.getDescription(this.work)}
      </${elementTag}>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-display-work-card': NFBDisplayWorkCard
  }
}
