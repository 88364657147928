/**
 * Class NFBContinueWatchingCarousel.
 *
 * Extends class NFBWorkCarousel.
 */

import { customElement } from 'lit/decorators.js'

import { selectContinueWatching, WorkHistory } from '@src/js/redux/workHistoryReducer'
import { fetchContinueWatchingWorks, selectWorkById, WorkCard } from '@src/js/redux/worksReducer'
import { store } from '@src/js/redux/store'
import { GTM_LIST_CATEGORY } from '@src/js/constants'
import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'

import NFBWorkCarousel from '@src/js/wc/carousels/base/NFBWorkCarousel'

@customElement('nfb-continue-watching-carousel')
export default class NFBContinueWatchingCarousel extends NFBWorkCarousel {
  _includeProgressBar: boolean = true
  _workHistory: WorkHistory[]
  _defaultSeeMoreCardText = window.pgettext('Continue watching carousel', 'See my history')

  carouselTitle: string = window.pgettext('Continue watching carousel', 'Continue watching')

  getCarouselGTMAttributes (): object {
    return {
      ...super.getCarouselGTMAttributes(),
      nfb_list_id: 'continueWatching',
      nfb_list_category: GTM_LIST_CATEGORY.CONTINUE
    }
  }

  async getRegistryIds () {
    await store.dispatch(fetchContinueWatchingWorks())
    const state = store.getState()
    this._workHistory = selectContinueWatching(state.workHistory)
    return this._workHistory.map(h => h.registry_id)
  }

  getContextWork (work: WorkCard): WorkCard | null {
    const state = store.getState()
    const relatedHistory = this._workHistory.find(h => h.registry_id === work.id)

    if (!relatedHistory?.work?.registry_id) {
      return null
    }

    const contextWork = selectWorkById(state.works, relatedHistory.work?.registry_id)
    return contextWork ?? null
  }
}
