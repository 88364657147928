import { postHistory, WorkHistoryReturnParams } from '@src/js/redux/workHistoryReducer'
import { AppDispatch } from '@src/js/redux/store'
import { sortByTimestamp } from '@src/js/helpers/sort'

export const fetchAllFromSessionStorage = (key: string): WorkHistoryReturnParams => {
  const unparsedSessionHistory: string|null = window.sessionStorage.getItem(key)
  const parsedSessionHistory = unparsedSessionHistory ? JSON.parse(unparsedSessionHistory) : []

  const returnData: WorkHistoryReturnParams = {
    data: parsedSessionHistory.sort(sortByTimestamp),
    count: parsedSessionHistory.length
  }

  return returnData
}

export const postSessionStorage = async (key: string, thunkAPI: {dispatch: AppDispatch}) => {
  const historySession = fetchAllFromSessionStorage(key).data
  if (historySession.length > 0) {
    for (const item of historySession) {
      await thunkAPI.dispatch(postHistory(item))
    }
    window.sessionStorage.removeItem(key)
  }
}
