import { WorkCard } from '@src/js/redux/worksReducer'
import { store } from '@src/js/redux/store'
import { selectActiveRentalForWork } from '@src/js/redux/userReducer'

export enum WorkAvailabilityStatus {
  PUBLIC = 'public',
  PROMO = 'promo',
  UNAVAILABLE = 'unavailable', // unavailable or geoblocked with no buy/rent options
  PURCHASE = 'purchase', // not public or geoblocked but has buy options
  RENTED = 'rented' // not public or geoblocked but rented
}

/** Status considered playable
 * (will eventually include RENTED)
 */
export const PLAYABLE_STATUS = [
  WorkAvailabilityStatus.PUBLIC
]

/**
 * @returns WorkAvailabilityStatus (public, promo, unavailable, buy)
 * based on availability data returned by API.
 * This status doesn't reflect ALL the availabilites, but
 * the "first" way the work can be watched.
 */
export const getAvailabilityStatusForWork = (work: WorkCard): WorkAvailabilityStatus => {
  const availabilityData = work.availability

  // Exception for interactive works: always public
  if (work.category === 'interactive') {
    return WorkAvailabilityStatus.PUBLIC
  }

  // Not availability data or no resource_url, return UNAVAILABLE
  if (!availabilityData || availabilityData?.resource_url === null) {
    return WorkAvailabilityStatus.UNAVAILABLE
  }

  // If can't be watched (geoblocked or not public)...
  if (work.geoblocked || availabilityData?.is_public === false) {
    // Look for buy options (RENTED or BUY)
    if ((availabilityData?.rent || availabilityData?.buy || availabilityData?.dvd)) {
      if (availabilityData?.rent && !!(selectActiveRentalForWork(store.getState().user, work))) {
        return WorkAvailabilityStatus.RENTED
      } else {
        return WorkAvailabilityStatus.PURCHASE
      }
    // If no buy options and geoblocked, return UNAVAILABLE
    } else if (work.geoblocked) {
      return WorkAvailabilityStatus.UNAVAILABLE
    // No buy options, not public and not geoblocked: PROMO is the only option left
    } else {
      return WorkAvailabilityStatus.PROMO
    }
  }

  return WorkAvailabilityStatus.PUBLIC
}
