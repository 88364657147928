/**
 * Class NFBUserRecommendationsCarousel.
 *
 * Extends class NFBWorkCarousel.
 */

import { customElement } from 'lit/decorators.js'

import { fetchUserRecommendationsWorks } from '@src/js/redux/worksReducer'
import { store } from '@src/js/redux/store'
import { GTM_LIST_CATEGORY, GTM_LIST_ID } from '@src/js/constants'
import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'

import NFBWorkCarousel from '@src/js/wc/carousels/base/NFBWorkCarousel'
import { selectUserRecommendationsIds } from '@src/js/redux/userReducer'

@customElement('nfb-user-recommendations-carousel')
export default class NFBUserRecommendationsCarousel extends NFBWorkCarousel {
  carouselTitle: string = window.pgettext('User recommendations carousel', 'Recommended for you')

  getCarouselGTMAttributes () {
    return {
      ...super.getCarouselGTMAttributes(),
      nfb_list_category: GTM_LIST_CATEGORY.USER_RECOMMENDATIONS,
      nfb_list_id: GTM_LIST_ID.USER_RECOMMENDATIONS
    }
  }

  async getRegistryIds () {
    await store.dispatch(fetchUserRecommendationsWorks())
    const state = store.getState()
    return selectUserRecommendationsIds(state.user)
  }
}
