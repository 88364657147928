import { PayloadAction } from '@reduxjs/toolkit'

import { selectWorkById, WorkCard } from '@src/js/redux/worksReducer'
import { setDisplayedWork } from '@src/js/redux/workPageReducer'

const workPageWiddleware = (store: any) => (next: any) => async (action: PayloadAction<any>) => {
  if (action.type.match('workPage/setDisplayedRegistryId/fulfilled')) {
    // When changing displayed registry id, update displayed work if found.
    const registryId = action.payload as number
    const work: WorkCard | null = selectWorkById(store.getState().works, registryId)
    if (work) {
      store.dispatch(setDisplayedWork(work))
    }
    next(action)
  } else {
    next(action)
  }
}

export default workPageWiddleware
