import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ApiV4Client } from '@src/js/ApiClient'
import { AppDispatch, RootState } from '@src/js/redux/store'
import { fetchByRegistryIds } from '@src/js/redux/worksReducer'

interface GetBookmarkApiResponse {
  items: [{ registry_id: number }]
}

export interface BookmarksState {
  list: number[] | null
  status: undefined | 'loading' | 'succeeded' | 'failed',
  currentLoadingId: number | null
}

const initialState: BookmarksState = {
  list: null,
  status: undefined,
  currentLoadingId: null
}

const apiV4Client = ApiV4Client.instance

export const fetchBookmarks = createAsyncThunk<GetBookmarkApiResponse>('bookmarks/fetch', async () => {
  const response = await apiV4Client.call({
    method: 'GET',
    url: '/bookmarks/'
  })

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  return await response.data
})

export const postBookmark = createAsyncThunk<
  number | null,
  number
>('bookmarks/add', async (registry_id: number): Promise<number|null> => {
  const response = await apiV4Client.call({
    method: 'POST',
    url: `/bookmarks/${registry_id}/`
  })

  return response.status === 201 ? registry_id : null
})

export const deleteBookmark = createAsyncThunk<
  number | null,
  number
>('bookmarks/delete', async (registry_id: number): Promise<number | null> => {
  const response = await apiV4Client.call({
    method: 'DELETE',
    url: `/bookmarks/${registry_id}/`
  })

  return response.status === 204 ? registry_id : null
})

export const fetchBookmarksList = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('bookmarks/fetchBookmarks', async (_, thunkAPI) => {
    await thunkAPI.dispatch(fetchBookmarks())
    const state: RootState = thunkAPI.getState()
    const idListToFetch: number[] = state.bookmarks.list!
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch, collection: 'gc/gc-films' }))
  })

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    setLoadingItem: (state, action: PayloadAction<number|null>) => {
      state.currentLoadingId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookmarks.pending, (state) => {
        state.status = 'loading'
        state.currentLoadingId = null
      })
      .addCase(fetchBookmarks.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.list = action.payload.items.map(i => i.registry_id)
      })
      .addCase(fetchBookmarks.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(postBookmark.pending, (state, action) => {
        state.status = 'loading'
        state.currentLoadingId = action.meta.arg
      })
      .addCase(postBookmark.fulfilled, (state, action: PayloadAction<number|null>) => {
        state.status = 'succeeded'
        state.currentLoadingId = null
        if (state.list !== null && action.payload) {
          state.list.unshift(action.payload)
        }
      })
      .addCase(postBookmark.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(deleteBookmark.pending, (state, action) => {
        state.status = 'loading'
        state.currentLoadingId = action.meta.arg
      })
      .addCase(deleteBookmark.fulfilled, (state, action: PayloadAction<number|null>) => {
        state.status = 'succeeded'
        state.currentLoadingId = null
        if (action.payload) {
          state.list = state.list!.filter(b => b !== action.payload)
        }
      })
      .addCase(deleteBookmark.rejected, (state) => {
        state.status = 'failed'
      })
  },
  selectors: {
    selectIdIsLoading: (state: BookmarksState, id: number): boolean => {
      return state.status === 'loading' && state.currentLoadingId === id
    },
    selectIdHasError: (state: BookmarksState, id: number): boolean => {
      return state.status === 'failed' && state.currentLoadingId === id
    },
    selectIdIsBookmarked: (state: BookmarksState, id: number): boolean => {
      return state.list !== null && state.list.includes(id)
    },
    selectBookmarksIds: (state: BookmarksState): number[] => {
      return state.list ?? []
    }
  }
})

export const {
  selectIdIsBookmarked,
  selectIdIsLoading,
  selectIdHasError,
  selectBookmarksIds
} = bookmarksSlice.getSelectors()

export default bookmarksSlice.reducer
