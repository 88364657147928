import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { ThunkAPI } from '@src/js/redux/store'
import { fetchByRegistryIds, WorkCard } from '@src/js/redux/worksReducer'
import { setNextIdReturnedByAPI } from '@src/js/redux/playersReducer'

export interface WorkPageState {
  pageIsReady: boolean
  keyartIsLoaded: boolean | null
  displayedRegistryId: number | null
  displayedWork: WorkCard | null
  highlightId: string | null
  lastClickElement: string|null
  currentActiveClickElement: string|null
}

const initialState: WorkPageState = {
  pageIsReady: false,
  keyartIsLoaded: null,
  displayedRegistryId: null,
  displayedWork: null,
  highlightId: null,
  lastClickElement: null,
  currentActiveClickElement: null
}

export const setDisplayedRegistryId = createAsyncThunk<
  number, number, ThunkAPI
>('workPage/setDisplayedRegistryId', async (registryId, thunkAPI) => {
  // Make sure we have info loaded from Public API
  const worksList = thunkAPI.getState().works.list
  if (!worksList || !(worksList.find(w => w.id === registryId))) {
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: [registryId] }))
  }
  // Reset next
  thunkAPI.dispatch(setNextIdReturnedByAPI(null))

  return registryId
})

export const workPageSlice = createSlice({
  name: 'workPage',
  initialState,
  reducers: {
    setPageIsReady (state, action: PayloadAction<boolean>) {
      state.pageIsReady = action.payload
    },
    initHighlightId (state) {
      const currentUrl = new URL(window.location.href)
      const highlightIdFromURL = currentUrl.searchParams.get('highlight')
      if (highlightIdFromURL) {
        state.highlightId = highlightIdFromURL
      }
    },
    initWork (state) {
      state.displayedRegistryId = window.DISPLAYED_WORK_ID ?? null
    },
    setHighlightId (state, action: PayloadAction<string | null>) {
      const { payload: id } = action
      state.highlightId = id
    },
    // dispatch when play is triggered
    setLastClickElement (state, action: PayloadAction<string|null>) {
      state.lastClickElement = action.payload
    },
    // dispatch when player is closed
    setCurrentActiveClickElement (state, action: PayloadAction<string|null>) {
      state.currentActiveClickElement = action.payload
    },
    setKeyartIsLoaded (state, action: PayloadAction<boolean>) {
      state.keyartIsLoaded = action.payload
    },
    setDisplayedWork (state, action: PayloadAction<WorkCard>) {
      state.displayedWork = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setDisplayedRegistryId.fulfilled, (state, action) => {
        state.displayedRegistryId = action.payload
      })
      .addCase(setDisplayedRegistryId.rejected, (state, action) => {
        console.log(action.error)
      })
  }
})

export const {
  setPageIsReady,
  initWork,
  initHighlightId,
  setHighlightId,
  setKeyartIsLoaded,
  setDisplayedWork,
  setLastClickElement,
  setCurrentActiveClickElement
} = workPageSlice.actions

export default workPageSlice.reducer
