/**
 * Class NFBNormanCarousel.
 *
 * Generic carousel displaying slotted elements within a NFBCarousel component.
 *
 */

import { LitElement, html, nothing, css, TemplateResult } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

// @ts-expect-error Lit Sass import
import carouselSlotStyle from '@web-nfb/frontend-static/design-system/wc/carousel/NFBCarouselSlot.sass?lit'
// @ts-expect-error Lit Sass import
import cardSlotStyle from '@web-nfb/frontend-static/design-system/wc/card/NFBCardSlot.sass?lit'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'

import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'
import { ObjectValues } from '@src/js/types/GTM'
import { GTM_LIST_CATEGORY, GTM_LIST_TYPE } from '@src/js/constants'

export type CarouselGTMAttrs = {
  nfb_list_type?: typeof GTM_LIST_TYPE.CAROUSEL,
  nfb_list_category?: ObjectValues<typeof GTM_LIST_CATEGORY>
  nfb_list_id?: string,
  nfb_list_name?: string,
}

@customElement('nfb-norman-carousel')
export default class NFBNormanCarousel extends LitElement {
  @property({ converter: stringToBooleanConverter, attribute: 'show-title' })
  showTitle = true

  @property({ type: String, attribute: 'carousel-title' })
  carouselTitle: string

  @property({ type: String, attribute: 'see-more-link' })
  seeMoreLink: string | null = null

  /** Show see more link as card. No effect if seeMoreLink is null. */
  @property({ converter: stringToBooleanConverter, attribute: 'show-see-more-card' })
  showSeeMoreCard = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-clothesline' })
  isClothesLine = false

  @property({ type: Object, attribute: 'gtm-attributes' })
  gtmAttributes: CarouselGTMAttrs = {}

  @state()
  isLoading = false

  _defaultSeeMoreCardText = window.gettext('See more')

  static get styles () {
    return [carouselSlotStyle, cardSlotStyle, css`
    .nfb-norman-carousel__spinner-container {
      min-height: 240px;
    }

    nfb-carousel::part(carousel) {
      background-color: transparent;
    }

    nfb-history-progress-bar::part(nfb-progress-bar) {
      position: absolute;
    }

    nfb-work-page-legend::part(container) {
      margin-top: 0;
      margin-bottom: 0;
    }

  `]
  }

  /**
   *
   * @returns show or hide see more card.
   * Based on the attribute by default. Can be overriden
   * for a different condition.
   */
  shouldShowSeeMoreCard () {
    return this.showSeeMoreCard
  }

  /**
   * @returns TemplateResult that is to be assigned to the "card" slot of the NFBCarousel
   * or nothing if no card to display.
   */
  getElements (): TemplateResult | typeof nothing {
    return html`<slot slot="card"></slot>`
  }

  /**
   * To be overwritten in extended carousel
   * @returns CarouselGTMAttrs with default nfb_list_type and nfb_list_name
   */
  protected getCarouselGTMAttributes (): CarouselGTMAttrs {
    return {
      nfb_list_type: GTM_LIST_TYPE.CAROUSEL,
      nfb_list_name: this.carouselTitle,
      ...this.gtmAttributes
    }
  }

  private getFormattedCarouselGTMAttributes () : string | typeof nothing {
    const gtmAttrs = this.getCarouselGTMAttributes()
    if (gtmAttrs) {
      return JSON.stringify(gtmAttrs)
    }

    return nothing
  }

  /**
   * @returns TemplateResult of a NFBCarousel or nothing if no elements to display.
   */
  getCarousel (): TemplateResult | typeof nothing {
    const carouselId = this.carouselTitle ? `${this.carouselTitle}-carousel`.toLowerCase() : nothing
    const elements = this.getElements()
    if (elements === nothing) {
      return nothing
    }

    return html`
      <nfb-carousel
        id="${carouselId}"
        data-cy="${carouselId}"
        title="${this.showTitle ? this.carouselTitle : nothing}"
        skip-list="${window.gettext('Skip This List')}"
        aria-label-previous="${window.gettext('See previous')} ${this.carouselTitle}"
        aria-label-next="${window.gettext('See next')} ${this.carouselTitle}"
        is-dark=${this.isDark ?? nothing}
        see-more-link=${this.seeMoreLink ?? nothing}
        see-more-card-text=${this.shouldShowSeeMoreCard() ? this._defaultSeeMoreCardText : nothing}
        is-clothesline=${this.isClothesLine ?? nothing}
        is-loading=${this.isLoading ?? nothing}
        gtm-attributes=${this.getFormattedCarouselGTMAttributes()}
      >
        ${this.getElements()}
      </nfb-carousel>
    `
  }

  render () {
    if (this.isLoading) {
      return html`
      <div class="nfb-norman-carousel__spinner-container">
        <nfb-spinner
          is-dark="${this.isDark ?? nothing}"
          is-loading="true"
        ></nfb-spinner>
      </div>
    `
    }

    return this.getCarousel()
  }
}
