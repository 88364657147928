import { configureStore } from '@reduxjs/toolkit'

import bookmarksReducer, { BookmarksState } from '@src/js/redux/bookmarksReducer'
import workHistoryReducer, { WorkHistoryState } from '@src/js/redux/workHistoryReducer'
import worksReducer, { WorksState } from '@src/js/redux/worksReducer'
import filmHistoryReducer, { FilmHistoryState } from '@src/js/redux/filmHistoryReducer'
import workPageReducer, { WorkPageState } from '@src/js/redux/workPageReducer'
import seriesPageReducer, { SeriesPageState } from '@src/js/redux/seriesPageReducer'
import playersReducer, { PlayersState } from '@src/js/redux/playersReducer'
import recommendationsReducer, { RecommendationsState } from '@src/js/redux/recommendationsReducer'
import userReducer, { UserState } from '@src/js/redux/userReducer'
import channelsReducer, { ChannelsState } from '@src/js/redux/channelsReducer'

import historyMiddleware from '@src/js/redux/historyMiddleware'
import seriesMiddleware from '@src/js/redux/seriesMiddleware'
import workPageWiddleware from '@src/js/redux/workPageMiddleware'

export type NFBStore = {
  bookmarks: BookmarksState
  channels: ChannelsState
  filmHistory: FilmHistoryState
  players: PlayersState
  recommendations: RecommendationsState
  seriesPage: SeriesPageState
  workHistory: WorkHistoryState
  workPage: WorkPageState
  user: UserState
  works: WorksState
}

export const store = configureStore({
  reducer: {
    bookmarks: bookmarksReducer,
    channels: channelsReducer,
    filmHistory: filmHistoryReducer,
    players: playersReducer,
    recommendations: recommendationsReducer,
    seriesPage: seriesPageReducer,
    workHistory: workHistoryReducer,
    workPage: workPageReducer,
    user: userReducer,
    works: worksReducer
  },
  // @ts-expect-error The expected type comes from property 'middleware' which is declared here on type 'ConfigureStoreOptions'
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      historyMiddleware,
      seriesMiddleware,
      workPageWiddleware
    )
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkAPI = {
  dispatch: AppDispatch,
  state: RootState
}

if (window.Cypress) {
  window.redux = store
}
