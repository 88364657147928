import {css} from 'lit';
export const styles = css`.nfb-work-page-creator {
  --nfb-work-page-creator-color: #000000;
  --nfb-work-page-link-hover-color: #1C7A3E;
  margin-bottom: 0.25rem;
}

.nfb-work-page-creator__separator {
  font-size: 0.875rem;
  font-weight: normal;
}

.nfb-work-page-creator__link {
  text-decoration: none;
  outline: none;
}

.nfb-work-page-creator__link:focus-visible {
  text-decoration: underline;
  color: var(--nfb-work-page-creator-color);
}

.nfb-work-page-creator__link--inactive {
  pointer-events: none !important;
  user-select: none !important;
}

.nfb-work-page-creator__link--inactive span {
  color: #c0bfbe !important;
}

.nfb-work-page-creator__link span {
  font-family: "Public Sans", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #1c1b1b;
  color: var(--nfb-work-page-creator-color);
  text-decoration: none;
}

.nfb-work-page-creator__link span:hover {
  color: var(--nfb-work-page-link-hover-color);
}

.nfb-work-page-creator--header {
  --nfb-work-page-creator-color: var(--header-infos-color);
  --nfb-work-page-link-hover-color: var(--nfb-work-page-creator-color);
  margin-bottom: 0;
}

.nfb-work-page-creator--header .nfb-work-page-creator__link span {
  font-family: "Public Sans", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1c1b1b;
  color: var(--nfb-work-page-creator-color);
}

.nfb-work-page-creator--header .nfb-work-page-creator__link span:hover {
  text-decoration: underline;
}

.nfb-work-page-creator--dark {
  --nfb-work-page-creator-color: #FFFFFF;
}

.nfb-work-page-creator--dark:not(.nfb-work-page-creator--header) {
  --nfb-work-page-link-hover-color: #05EE80;
}`;
export default styles;
