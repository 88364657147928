import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { PublicApiV5Client } from '@src/js/ApiClient'
import { WorkCard, addWorkToList, fetchByRegistryIds } from '@src/js/redux/worksReducer'
import { store, ThunkAPI } from '@src/js/redux/store'
import { setQueue } from '@src/js/redux/playersReducer'
import { selectFilmHistoryById } from '@src/js/redux/filmHistoryReducer'

const publicApiV5Client = PublicApiV5Client.instance

const API_ENDPOINT_FOR_TYPE = {
  collection: 'collections',
  series: 'series'
}

export type Season = {
  id: number
  slug: string
  title: string
  number: number
  year: number
  episodes: WorkCard[]
}

export type SeriesCard = {
  seasons: Season[]
} & WorkCard

export interface SeriesApiResponse {
  item: SeriesCard
}

type EpisodesList = Record<number, number[]>

export interface SeriesPageState {
  seriesRegistryId: number | null
  seriesSlug: string | null
  seriesType: string | null
  displayedSeasonNumber: number | null
  displayedSeasonTitle: string | null
  seasonList: Season[]
  episodesList: EpisodesList,
  allEpisodeIdList: number[],
}

const initialState: SeriesPageState = {
  seriesRegistryId: null,
  seriesSlug: null,
  seriesType: null,
  displayedSeasonNumber: null,
  displayedSeasonTitle: null,
  seasonList: [],
  episodesList: {},
  allEpisodeIdList: []
}

export const fetchSeries = createAsyncThunk<
  SeriesApiResponse, void, ThunkAPI
>('seriesPage/fetchSeries', async (_, thunkAPI) => {
  const state = thunkAPI.getState().seriesPage

  const seriesEndpoint = state.seriesType
    ? API_ENDPOINT_FOR_TYPE[state.seriesType as keyof typeof API_ENDPOINT_FOR_TYPE]
    : API_ENDPOINT_FOR_TYPE.series

  const response = await publicApiV5Client.call({
    method: 'GET',
    url: `/${seriesEndpoint}/${state.seriesSlug}`
  })

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  const seriesData = await response.data as SeriesApiResponse
  thunkAPI.dispatch(addWorkToList([seriesData.item]))

  const seasons: Season[] = seriesData.item.seasons
  const episodesList: EpisodesList = {}
  const episodesIdsList: number[] = []
  seasons.forEach(s => {
    const idsList = s.episodes.map(e => e.id)
    episodesList[s.number] = idsList
    episodesIdsList.push(...idsList)
  })

  thunkAPI.dispatch(setEpisodesList(episodesList))
  thunkAPI.dispatch(setAllEpisodeIdList(episodesIdsList))
  await thunkAPI.dispatch(fetchByRegistryIds({ idList: episodesIdsList }))

  if (seriesData.item.category === 'collection') {
    const reversesSeasons = [...seasons].reverse()
    const reverseEpisodesList = reversesSeasons.map(s => s.episodes.map(e => e.id)).flat()
    thunkAPI.dispatch(setQueue(reverseEpisodesList))
    thunkAPI.dispatch(setSeasonList(reversesSeasons))
  } else {
    thunkAPI.dispatch(setQueue(episodesIdsList))
    thunkAPI.dispatch(setSeasonList(seasons))
  }

  return seriesData
})

export const seriesPageSlice = createSlice({
  name: 'seriesPage',
  initialState,
  reducers: {
    initSeries (state) {
      state.seriesRegistryId = window.SERIES_ID
      state.seriesSlug = window.SERIES_SLUG
      state.seriesType = window.SERIES_TYPE
      state.displayedSeasonNumber = parseInt(window.SEASON_NUMBER)
      state.displayedSeasonTitle = window.SEASON_TITLE
    },
    setDisplayedSeasonNumber (state, action) {
      state.displayedSeasonNumber = action.payload
    },
    setDisplayedSeasonTitle (state, action) {
      state.displayedSeasonTitle = action.payload
    },
    setSeasonList (state, action) {
      state.seasonList = action.payload
      // when seasonList is updated,
      // check if displayedSeasonNumber is valid
      // otherwhise, take the first season's number
      if (state.displayedSeasonNumber) {
        const validSeason = (state.displayedSeasonNumber in state.episodesList)
        if (!validSeason) {
          state.displayedSeasonNumber = state.seasonList[0].number
          state.displayedSeasonTitle = state.seasonList[0].title
        }
      }
    },
    setEpisodesList (state, action) {
      state.episodesList = action.payload
    },
    setAllEpisodeIdList (state, action) {
      state.allEpisodeIdList = action.payload
    }
  },
  selectors: {
    selectCurrentEpisodesList: (state) => {
      const currentSeason = state.displayedSeasonNumber
      if (!currentSeason || !(currentSeason in state.episodesList)) return null

      return state.episodesList[currentSeason]
    },
    selectSeriesIsCompleted: (state) => {
      let allCompleted = true
      state.allEpisodeIdList?.some(registryId => {
        const filmHistory = selectFilmHistoryById(store.getState().filmHistory, registryId)

        // If no history found, episode/film not started
        if (!filmHistory || filmHistory.is_completed === false) {
          allCompleted = false
          return true
        }

        return false
      })

      return allCompleted
    }
  }
})

export const {
  initSeries,
  setDisplayedSeasonNumber,
  setDisplayedSeasonTitle,
  setSeasonList,
  setEpisodesList,
  setAllEpisodeIdList
} = seriesPageSlice.actions

export const {
  selectCurrentEpisodesList,
  selectSeriesIsCompleted
} = seriesPageSlice.getSelectors()

export default seriesPageSlice.reducer
