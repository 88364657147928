import uniqBy from 'lodash/uniqBy'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { Language } from '@web-nfb/player/src/types'

import { PublicApiV5Client } from '@src/js/ApiClient'
import { fetchAll, fetchAllContinueWatching, selectContinueWatchingAndParentWorkIds, selecViewingHistoryAndParentWorkIds, GetWatchHistoryParams } from '@src/js/redux/workHistoryReducer'
import { AppDispatch, RootState } from '@src/js/redux/store'
import { fetchFilmHistoryByRegistryIds } from '@src/js/redux/filmHistoryReducer'
import { fetchRecommendations, selectRecommendationsIds } from '@src/js/redux/recommendationsReducer'
import { StreamTypes } from '@src/js/redux/playersReducer'
import { fetchUserRecommendations, selectUserRecommendationsIds } from '@src/js/redux/userReducer'

const publicApiV5Client = PublicApiV5Client.instance

export interface WorkDirector {
  first_name: string,
  last_name: string,
  slug: string
}

export interface ExtraCard {
  content_type: StreamTypes
  description: string
  duration: number
  id: number
  slug: string
  thumbnail: string
  title: string
  year: number
}

export interface WorkCard {
  id: number
  title: string
  slug: string
  duration: number
  year: number
  thumbnail: string
  label?: string
  directors?: WorkDirector[]
  producers?: WorkDirector[]
  category: 'film'|'series'|'collection'|'interactive'
  description: string
  tagline?: string
  cc?: boolean
  dv?: boolean
  rating?: string
  coming_soon?: string
  is_new_release?: boolean
  leaving_soon?: string
  title_treatment?: string | null
  key_art_square?: string | null
  key_art_horizontal?: string | null
  story_art_horizontal?: string | null
  story_art_vertical?: string | null
  story_art_square?: string | null
  geoblocked?: boolean
  warning?: string
  availability?: {
    resource_url?: string | null
    buy?: boolean
    dvd?: boolean
    is_public?: boolean
    rent?: boolean
  }
  subjects?: string[]
  credits_timecodes?: {
    opening: number | null
    ending: number | null
  }
  year_max?: number
  year_min?: number
  episodes_count?: number
  seasons_count?: number
  genres?: string[]
  extras?: Record<StreamTypes, ExtraCard[]>
  cataloging_language: Language
  opening_credits_timecode?: number | null
  ending_credits_timecode?: number | null
  part_of?: [
    {
      category: 'collection' | 'series'
      registry_id: number
      slug: string
    }
  ]
}

export interface APIWorksResponse {
  items: WorkCard[],
  meta: {
    mixed_media: {
      thumbnail_url: {
        film: string,
        collection: string,
        interactive: string,
        series: string
      }
    }
  }
}

export interface APITypeResponse {
  item: {
    subjects: [
      {
        category_type: string
        slug: string
        name: string
        children: [
          {
            category_type: string | null,
            slug: string
            name: string
          }
        ]
      },
    ]
  }
}

export const basicIncludeFields = [
  'slug', 'duration', 'year', 'thumbnail', 'directors', 'cc', 'rating', 'coming_soon',
  'is_new_release', 'leaving_soon', 'availability', 'geoblocked', 'producers', 'year_max',
  'year_min', 'seasons_count', 'episodes_count'
]

const detailedIncludeFields = [
  ...basicIncludeFields,
  'tagline', 'description', 'title_treatment', 'key_art_square', 'subjects', 'genres', 'extras'
]

export interface WorksState {
  list: WorkCard[] | null,
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  thumbnailPattern: {
    [key: string]: string
  }
}

const initialState: WorksState = {
  list: null,
  status: 'idle',
  thumbnailPattern: {}
}

interface GetWorksParams {
  idList: number [],
  collection?: 'nfb/public' | 'gc/gc-films',
  includeFields?: string []
}

export const fetchByRegistryIds = createAsyncThunk<
  APIWorksResponse, GetWorksParams
>('works/fetch', async ({ idList, collection = 'nfb/public', includeFields = detailedIncludeFields }) => {
  const response = await publicApiV5Client.call({
    method: 'GET',
    url: '/works',
    params: {
      registry_ids: idList.join(','),
      include_series_episodes: true,
      include_fields: includeFields,
      locale: window.LOCALE,
      size: idList.length,
      collection
    }
  }) as AxiosResponse

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  return await response.data
})

export const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    addWorkToList: (state, action: PayloadAction<WorkCard[]>) => {
      state.list = uniqBy([
        ...action.payload,
        ...(state.list ?? [])
      ], 'id')
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchByRegistryIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchByRegistryIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.list = uniqBy([
          ...action.payload.items,
          ...(state.list ?? [])
        ], 'id')
        state.thumbnailPattern = action.payload.meta.mixed_media.thumbnail_url
      })
      .addCase(fetchByRegistryIds.rejected, (state, action) => {
        state.status = 'failed'
        console.log(action.error)
        // @todo: handle errors
      })
  },
  selectors: {
    selectWorkById: (state: WorksState, id: number) => {
      if (!state.list) return null

      return state.list.find(work => work.id === id) ?? null
    },
    selectWorkByIdList: (state: WorksState, idList: number[]): WorkCard[] | null => {
      if (!state.list) return null

      if (!idList) {
        return []
      }
      const works = state.list.filter(work => idList.includes(work.id))
      // Return works in the same order as idList
      return works.sort((a, b) => idList.indexOf(a.id) - idList.indexOf(b.id))
    }
  }
})

export const fetchContinueWatchingWorks = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('works/fetchContinueWatching', async (_, thunkAPI) => {
    await thunkAPI.dispatch(fetchAllContinueWatching())
    const state: RootState = thunkAPI.getState()
    const idListToFetch: number[] = selectContinueWatchingAndParentWorkIds(state.workHistory)
    await thunkAPI.dispatch(fetchFilmHistoryByRegistryIds(idListToFetch))
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch }))
  })

export const fetchViewingHistory = createAsyncThunk<
  void,
  GetWatchHistoryParams,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('works/fetchViewingHistory', async (params, thunkAPI) => {
    await thunkAPI.dispatch(fetchAll(params))
    const state: RootState = thunkAPI.getState()
    const idListToFetch: number[] = selecViewingHistoryAndParentWorkIds(state.workHistory)
    await thunkAPI.dispatch(fetchFilmHistoryByRegistryIds(idListToFetch))
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch }))
  })

export const fetchRecommendationsWorks = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('works/fetchRecommendations', async (mainWorkRegistryId, thunkAPI) => {
    let mainWork = selectWorkById(thunkAPI.getState().works, mainWorkRegistryId)

    if (!mainWork) {
      const mainWorkResponse = (await thunkAPI.dispatch(fetchByRegistryIds({ idList: [mainWorkRegistryId], collection: 'gc/gc-films' }))).payload as APIWorksResponse
      if (mainWorkResponse.items?.length) {
        mainWork = mainWorkResponse.items[0]
      } else {
        return
      }
    }

    await thunkAPI.dispatch(fetchRecommendations(mainWork))
    const idListToFetch: number[] | undefined = selectRecommendationsIds(thunkAPI.getState().recommendations)

    if (!idListToFetch) {
      return
    }

    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch }))
  })

export const fetchUserRecommendationsWorks = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('works/fetchUserRecommendations', async (_, thunkAPI) => {
    await thunkAPI.dispatch(fetchUserRecommendations())
    const state: RootState = thunkAPI.getState()
    const idListToFetch: number[] = selectUserRecommendationsIds(state.user)
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch }))
  })

export const { addWorkToList } = worksSlice.actions

export const { selectWorkById, selectWorkByIdList } = worksSlice.getSelectors()

export default worksSlice.reducer
