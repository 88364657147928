import qs from 'qs'
import axios, { AxiosRequestConfig } from 'axios'

declare global {
  interface Window {
    PUBLIC_API_ENDPOINT: string
  }
}

type ApiClientOptions = {
  baseURL?: string
  basePath?: string
}

export const WORK_CATEGORY_TO_TYPE = {
  film: 'films',
  series: 'series',
  collection: 'collections',
  interactive: 'interactive'
}

abstract class ApiClient {
  declare axios
  baseURL: string
  basePath: string

  constructor ({ basePath, baseURL }: ApiClientOptions) {
    this.baseURL = baseURL ?? ''
    this.basePath = basePath ?? ''
    this.axios = axios.create({
      baseURL: `${this.baseURL}${this.basePath}`
    })
  }

  call ({ method, url, data, headers, params }: AxiosRequestConfig) {
    return this.axios({
      method,
      url,
      data,
      params,
      headers,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
}

class ApiV4Client extends ApiClient {
  private static _instance = new ApiV4Client()

  private constructor () {
    super({ basePath: '/api/v4' })

    this.axios.defaults.xsrfCookieName = 'csrftoken'
    this.axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    this.axios.defaults.withCredentials = true
  }

  static get instance () { return ApiV4Client._instance }
}

class PublicApiV5Client extends ApiClient {
  private static _instance = new PublicApiV5Client()

  private constructor () {
    super({ baseURL: window.PUBLIC_API_ENDPOINT, basePath: '/api/v5' })
  }

  static get instance () { return PublicApiV5Client._instance }

  call ({ method, url, data, headers, params }: AxiosRequestConfig) {
    return super.call({
      method,
      url,
      data,
      headers,
      params: {
        collection: 'nfb/public',
        ...params
      }
    })
  }
}

export { ApiV4Client, PublicApiV5Client }
