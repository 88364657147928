import {css} from 'lit';
export const styles = css`@charset "UTF-8";
.nfb-legend {
  width: 100%;
  font-family: "Public Sans", Georgia, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000;
  color: #6b6a69;
  margin-bottom: 1rem;
}

.nfb-legend__item {
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.nfb-legend__item + .nfb-legend__item::before {
  content: "|";
  margin-right: 0.5rem;
}

.nfb-legend__icon:not(:last-child) {
  margin-right: 0.5rem;
}

.nfb-legend {
  color: var(--nfb-card-legend-color);
  width: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.nfb-legend-container {
  display: flex;
  align-items: first baseline;
  flex-wrap: wrap;
}

.nfb-legend nfb-legend-item:not(.nfb-card__creator) + nfb-legend-item::before, .nfb-legend nfb-legend-item:not(.nfb-card__creator) + nfb-legend-icons::before {
  content: "•";
  margin-right: 0.5rem;
}

.nfb-legend nfb-legend-item, .nfb-legend nfb-legend-icons {
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
  font-size: 0.75rem;
}`;
export default styles;
