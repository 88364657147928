/**
 * Work history used for continue watching carrousel or history listing.
 * We only want history information about the "main" work for series/collections
 */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import { ApiV4Client } from '@src/js/ApiClient'
import { ThunkAPI } from '@src/js/redux/store'

import { FilmHistory, updateFilmHistoryList } from '@src/js/redux/filmHistoryReducer'
import { setNextIdReturnedByAPI } from '@src/js/redux/playersReducer'
import { fetchAllFromSessionStorage, postSessionStorage } from '@src/js/helpers/sessionStorage'
import { sortByTimestamp } from '@src/js/helpers/sort'
import { DEFAULT_LIST_LENGTH } from '@src/js/constants'

const SESSION_WORK_HISTORY_KEY = 'nfb_session_work_history'
const apiV4Client = ApiV4Client.instance

export interface WorkHistory extends FilmHistory {
  work?: { // only for series/collection type
    is_completed?: boolean
    registry_id: number,
  }
}

export interface UpdateWorkHistoryResponse {
  code: string,
  message: string
}

export interface GetWatchHistoryParams {
  is_completed?: boolean,
  registry_id?: number,
  per_page?: number,
  page?: number
}

export interface WorkHistoryState {
  list: WorkHistory[],
  continueWatchingList: WorkHistory[],
  countContinueWatching: number,
  countWatchHistory: number
  status: undefined | 'idle' | 'loading' | 'succeeded' | 'failed'
}

export interface WorkHistoryReturnParams {
  data: WorkHistory[],
  count: number,
}

export const fetchAllFromApi = async (params: GetWatchHistoryParams) => {
  const response = await apiV4Client.call({
    method: 'GET',
    url: '/watch_history/works',
    params
  })

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  return await response.data
}

export const fetchAll = createAsyncThunk<
  WorkHistoryReturnParams,
  GetWatchHistoryParams,
  ThunkAPI
  >('workHistory/fetchAll', async ({ per_page = DEFAULT_LIST_LENGTH, is_completed, registry_id, page }, thunkAPI) => {
    if (window.USER_CONNECTED) {
      // user has data in session storage ?
      // we need to sync
      await postSessionStorage(SESSION_WORK_HISTORY_KEY, thunkAPI)

      return await fetchAllFromApi({ per_page, is_completed, page, registry_id })
    } else {
      let sessionHistory = fetchAllFromSessionStorage(SESSION_WORK_HISTORY_KEY)
      if (registry_id) {
        const filteredSessionHistory = sessionHistory.data.filter((f: WorkHistory) => f.work && registry_id === f.work.registry_id)
        sessionHistory = {
          data: filteredSessionHistory,
          count: filteredSessionHistory.length
        }
      }
      return sessionHistory
    }
  })

export const fetchAllContinueWatching = createAsyncThunk<
  WorkHistoryReturnParams,
  void,
  ThunkAPI
  >('workHistory/fetchAllContinueWatching', async (_, thunkAPI) => {
    if (window.USER_CONNECTED) {
      await postSessionStorage(SESSION_WORK_HISTORY_KEY, thunkAPI)
      // Fetch one more than default list length to trigger see more card behavior if necessary.
      return await fetchAllFromApi({ is_completed: false, per_page: DEFAULT_LIST_LENGTH + 1 })
    } else {
      return []
    }
  })

export const postHistory = createAsyncThunk<
WorkHistory, WorkHistory, ThunkAPI
>('workHistory/post', async (history, thunkAPI) => {
  if (!history.timestamp) {
    history.timestamp = Date.now()
  }

  if (window.USER_CONNECTED) {
    const response = await apiV4Client.call({
      method: 'POST',
      url: '/watch_history/works',
      data: history
    })

    if (response.status !== 200) {
      throw new Error(`Response status: ${response.status}`)
    }

    const returnedHistory = (await response.data).data

    // Only for series/collection
    if (history.work?.registry_id !== history.registry_id) {
      // Next item sent by API
      if (returnedHistory.registry_id !== history.registry_id) {
        // Current is ended
        history.is_completed = true
        // Parent work could be completed to
        history.work = returnedHistory.work
        // Save next in store
        thunkAPI.dispatch(setNextIdReturnedByAPI(returnedHistory.registry_id))
        // Save current completed in film history
        thunkAPI.dispatch(updateFilmHistoryList([history]))
      }
    }

    return returnedHistory
  } else {
    history.is_completed = history.percent >= 95

    return history
  }
})

const initialState: WorkHistoryState = {
  list: fetchAllFromSessionStorage(SESSION_WORK_HISTORY_KEY).data ?? [],
  continueWatchingList: [],
  countContinueWatching: 0,
  countWatchHistory: 0,
  status: undefined
}

export const workHistorySlice = createSlice({
  name: 'workHistory',
  initialState,
  reducers: {
    updateList (state: WorkHistoryState, action: PayloadAction<{data: WorkHistory[]}>) {
      // Work history should return only one film by work id
      const tempList = uniqBy([
        ...action.payload.data,
        ...state.list
      ], 'work.registry_id')

      state.list = tempList.sort(sortByTimestamp)

      // Record in local storage
      if (!window.USER_CONNECTED) {
        window.sessionStorage.setItem(SESSION_WORK_HISTORY_KEY, JSON.stringify(state.list))
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAll.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.countWatchHistory = action.payload.count
        workHistorySlice.caseReducers.updateList(state, action)
      })
      .addCase(fetchAll.rejected, state => {
        state.status = 'failed'
        // @todo: handle errors
      })
      .addCase(fetchAllContinueWatching.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAllContinueWatching.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.continueWatchingList = action.payload.data
        state.countContinueWatching = action.payload.count
        workHistorySlice.caseReducers.updateList(state, action)
      })
      .addCase(fetchAllContinueWatching.rejected, state => {
        state.status = 'failed'
        // @todo: handle errors
      })
      .addCase(postHistory.pending, state => {
        state.status = 'loading'
      })
      .addCase(postHistory.fulfilled, (state: WorkHistoryState, action) => {
        state.status = 'succeeded'
        workHistorySlice.caseReducers.updateList(state, { payload: { data: [action.payload] }, type: 'workHistory/updateList' })
      })
      .addCase(postHistory.rejected, (state, action) => {
        state.status = 'failed'
        // If post failed, save data from action arg to list
        const history = action.meta.arg
        workHistorySlice.caseReducers.updateList(state, { payload: { data: [history] }, type: 'workHistory/updateList' })
      })
  },
  selectors: {
    selectWorkHistoryById: (state: WorkHistoryState, id: number): WorkHistory | undefined => {
      return state.list.find(item => item.registry_id === id)
    },
    selectContinueWatching: (state: WorkHistoryState) => {
      return state.continueWatchingList
    },
    selectContinueWatchingIds: (state: WorkHistoryState) => {
      return state.continueWatchingList
        .map(item => (item.registry_id) as number)
    },
    selectContinueWatchingAndParentWorkIds: (state: WorkHistoryState) => {
      const filmIds: number[] = workHistorySlice.getSelectors().selectContinueWatchingIds(state)
      const viewContextIds: number[] = state.continueWatchingList
        .map(item => (item.work?.registry_id) as number)
      return [...filmIds, ...viewContextIds]
    },
    selectHistoryForParentWork: (state, parentRegistryId: number): WorkHistory[] | null => {
      return state.list
        .filter(h => h.work?.registry_id === parentRegistryId)
        .sort(sortByTimestamp)
    },
    selectViewingHistoryIds: (state: WorkHistoryState) => {
      return state.list
        .map(item => (item.registry_id) as number)
    },
    selecViewingHistoryAndParentWorkIds: (state: WorkHistoryState) => {
      const filmIds: number[] = workHistorySlice.getSelectors().selectViewingHistoryIds(state)
      const viewContextIds: number[] = state.list
        .map(item => (item.work?.registry_id) as number)
      return [...filmIds, ...viewContextIds]
    }
  }
})

export const {
  selectWorkHistoryById,
  selectContinueWatching,
  selectContinueWatchingIds,
  selectContinueWatchingAndParentWorkIds,
  selectHistoryForParentWork,
  selectViewingHistoryIds,
  selecViewingHistoryAndParentWorkIds
} = workHistorySlice.getSelectors()

export default workHistorySlice.reducer
