import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'
import { LanguageVariant } from '@web-nfb/frontend-static/design-system/wc/legend/legendIcons/NFBLegendIcons'
import { html, css, LitElement, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { formatDuration } from '@src/js/helpers/format'

// @ts-expect-error Lit Sass import
import styles from '@src/sass/wc/NFBWorkPageLegend.sass?lit'

@customElement('nfb-work-page-legend')
export default class NFBWorkPageLegend extends LitElement {
  @property({ converter: stringToBooleanConverter, attribute: 'is-cc' })
  isCC = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-dv' })
  isDV = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-leaving-soon' })
  isLeavingSoon = false

  @property({ type: String, attribute: 'language' })
  language = LanguageVariant.en

  @property({ type: String, attribute: 'year' })
  year = ''

  @property({ type: String, attribute: 'running-time' })
  runningTime = ''

  @property({ type: String, attribute: 'series-info' })
  seriesInfo = ''

  @property({ type: String, attribute: 'rating' })
  rating = ''

  @property({ type: String, attribute: 'genre' })
  genre = ''

  static styles = [styles,
    css`
    :host(.nfb-work-page-legend) {
      nfb-legend-item {
        font-size: 1rem;
      }
    }
  `
  ]

  render () {
    return html`
      <div
        class="nfb-work-page-legend nfb-legend"
        part="container"
      >
    ${this.year
    ? html`
        <nfb-legend-item
          data-cy="work-page-legend-year"
          text="${this.year}"
        >
        </nfb-legend-item>`
    : nothing}
    ${this.runningTime
    ? html`
        <nfb-legend-item
          data-cy="work-page-legend-running-time"
          text="${formatDuration(this.runningTime)}"
        >
        </nfb-legend-item>`
    : nothing}
    ${this.seriesInfo
    ? html`
        <nfb-legend-item
          data-cy="work-page-legend-series-info"
          text="${this.seriesInfo}"
        >
        </nfb-legend-item>`
    : nothing}
    ${this.genre
    ? html`
        <nfb-legend-item
          data-cy="work-page-legend-genre"
          text="${this.genre}"
        >
        </nfb-legend-item>`
    : nothing}
    ${this.isCC || this.isDV || this.rating
    ? html`
        <nfb-legend-icons
          language="${this.language}"
          is-dv="${this.isDV}"
          is-cc="${this.isCC}"
          rating="${this.rating}"
        >
        </nfb-legend-icons>`
    : nothing}
      <slot></slot>
    </div>
    `
  }
}
