import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiV4Client, WORK_CATEGORY_TO_TYPE } from '@src/js/ApiClient'
import { WorkCard } from '@src/js/redux/worksReducer'
import { DEFAULT_LIST_LENGTH } from '@src/js/constants'

const apiV4Client = ApiV4Client.instance

export interface RecommendationsState {
  list: number[] | undefined
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: RecommendationsState = {
  list: undefined,
  status: 'idle'
}

export const fetchRecommendations = createAsyncThunk<
  number[], WorkCard
>('recommendations/fetch', async (work: WorkCard) => {
  const recommendationsResponse = await apiV4Client.call({
    method: 'GET',
    url: `${WORK_CATEGORY_TO_TYPE[work.category]}/${work.slug}/recommendations`,
    params: {
      size: DEFAULT_LIST_LENGTH + 1
    }
  })

  const ids = (await recommendationsResponse.data).data.map((item: any) => item.registry_id)
  return ids
})

export const recommendationsSlice = createSlice({
  name: 'recommendations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRecommendations.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.list = action.payload ?? []
      })
      .addCase(fetchRecommendations.rejected, (state) => {
        state.status = 'failed'
      })
  },
  selectors: {
    selectRecommendationsIds: (state: RecommendationsState) => {
      return state.list
    }
  }
})

export const {
  selectRecommendationsIds
} = recommendationsSlice.getSelectors()

export default recommendationsSlice.reducer
