/**
 * Class NFBWorkCarousel.
 *
 * Generic carousel displaying NFBDisplayWork elements within a NFBCarousel component
 * based on a list of registry ids passed as attributes.
 *
 */

import { html, nothing, PropertyValues, TemplateResult } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import normanPaths from '@src/js/helpers/paths'
import { GTMSelectWork } from '@src/js/types/GTM'
import { GTM_EVENTS, DEFAULT_LIST_LENGTH } from '@src/js/constants'
import { fetchByRegistryIds, selectWorkByIdList, WorkCard } from '@src/js/redux/worksReducer'
import NFBNormanCarousel, { CarouselGTMAttrs } from './NFBNormanCarousel'
import { store } from '@src/js/redux/store'
import { bookmarkButtonType } from '@src/js/wc/cards/NFBDisplayWorkCard'

type SelectWorkGTMEvent = {
  event: typeof GTM_EVENTS.SELECT_WORK,
} & CarouselGTMAttrs

@customElement('nfb-work-carousel')
export default class NFBWorkCarousel extends NFBNormanCarousel {
  @property({ type: Array, attribute: 'registry-id-list' })
  initialRegistryIdList: null | number[] = null

  @property({ type: String, attribute: 'collection' })
  collection: 'nfb/public' | 'gc/gc-films' = 'nfb/public'

  /** List of work to display. Is null if works have not
   * been fetched yet, empty if fetch is completed but no
   * work to display.
   */
  @state()
  worksList: WorkCard[] | null = null

  _includeProgressBar = false
  _includeBookmarkButton = true

  /**
   * After update, calls getRegistryIds() and use
   * the returned list to select works.
   */
  async connectedCallback (): Promise<void> {
    super.connectedCallback()
    this.isLoading = true
    await this.updateComplete
    const registryIds = await this.getRegistryIds()
    this.worksList = selectWorkByIdList(store.getState().works, registryIds) ?? []
  }

  protected updated (_changedProperties: PropertyValues): void {
    if (_changedProperties.has('worksList') && this.worksList !== null && this.isLoading) {
      this.isLoading = false
    }
  }

  /**
   * Called for each card in getWorkCard.
   * @returns GTM dict containing all generic attributes
   * for nfb_select_work related to this carousel. Will be used
   * to send work specific event on card click.
   *
   * Should be overwritten in child class to set up specific values.
   */
  getGTMAttributesForSelectWork (): SelectWorkGTMEvent {
    if (!this.carouselTitle) {
      // Throw an error if no title has been attributed,
      // since this will cause bad data for GTM event.
      throw new Error('No carousel title has been defined.')
    }

    return {
      ...this.getCarouselGTMAttributes(),
      event: GTM_EVENTS.SELECT_WORK
    }
  }

  /**
   *
   * @returns A list of registry ids to be displayed. In the generic
   * version, returns value of registry-id-list attribute. Should be
   * overwritten in child class if registry-id-list should be fetched
   * from store.
   */
  async getRegistryIds (): Promise<number[]> {
    if (!this.initialRegistryIdList?.length) {
      return []
    }

    await store.dispatch(fetchByRegistryIds({
      idList: this.initialRegistryIdList,
      collection: this.collection
    }))
    return this.initialRegistryIdList
  }

  shouldShowSeeMoreCard () {
    return this.showSeeMoreCard &&
    !!this.worksList?.length && this.worksList.length > DEFAULT_LIST_LENGTH
  }

  getElements () {
    if (!this.worksList?.length) {
      this.classList.add('nfb-carousel--empty')
      return nothing
    }

    this.classList.remove('nfb-carousel--empty')

    const cardMax = this.shouldShowSeeMoreCard()
      ? DEFAULT_LIST_LENGTH - 1
      : DEFAULT_LIST_LENGTH

    return html`${
      this.worksList?.slice(0, cardMax).map((work, index) => this.getWorkCard(work, index))
    }`
  }

  /**
   *
   * @param work
   * @returns The context work (series or collection), or null if no
   * context. Thumbnail and resource path of the context work will be
   * used when displaying card for work.
   *
   * Returns null by default, should be overwritten if a context work
   * is to be used.
   */
  getContextWork (work: WorkCard): WorkCard | null { return null }

  /**
   *
   * @param work
   * @param index
   * @returns TemplateResult containing nfb-display-work card to be
   * displayed
   */
  getWorkCard (work: WorkCard, index: number): TemplateResult {
    const gtmAttrs: GTMSelectWork = {
      ...this.getGTMAttributesForSelectWork(),
      nfb_version_title: work.title,
      nfb_detail: (index + 1).toString()
    }

    const isInteractive = work.category === 'interactive'
    const bookmarkButton = store.getState().user.isConnected && !isInteractive && this._includeBookmarkButton
      ? bookmarkButtonType.add
      : nothing

    const contextWork = this.getContextWork(work) ?? work

    return html`
      <li slot="card" class="nfb-carousel__card" id="${this.title}-${work.title}-${index}" data-index="${index}">
        <nfb-display-work-card
          data-ui-el="carousel-card"
          registry-id="${work.id}"
          thumbnail-path="${contextWork.thumbnail}"
          include-progress-bar="${this._includeProgressBar ?? nothing}"
          gtm-attributes="${JSON.stringify(gtmAttrs)}"
          href="${normanPaths[contextWork.category].replace('{slug}', contextWork.slug)}"
          is-dark="${this.isDark ?? nothing}"
          primary-variant="${isInteractive ? 'square' : nothing}"
          secondary-variant="${this.isClothesLine ? 'no-border' : nothing}"
          card-type="carousel"
          bookmark-button-type=${bookmarkButton}
        >
        </nfb-display-work-card>
      </li>`
  }
}
