/**
 * Film history used for work page detail
 * Retrieve only history for a specific film,
 * or to get history for a list of episodes/films if it's a series/collection
 */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import { ApiV4Client } from '@src/js/ApiClient'
import { ThunkAPI } from '@src/js/redux/store'
import { fetchAllFromSessionStorage, postSessionStorage } from '@src/js/helpers/sessionStorage'

const apiV4Client = ApiV4Client.instance
const SESSION_FILM_HISTORY_KEY = 'nfb_session_film_history'

export interface FilmHistory {
  registry_id: number
  timecode: number
  percent: number
  timestamp?: number
  is_completed?: boolean
}

export interface FilmHistoryState {
  list: FilmHistory[],
  status: undefined | 'loading' | 'succeeded' | 'failed'
}

const initialState: FilmHistoryState = {
  list: fetchAllFromSessionStorage(SESSION_FILM_HISTORY_KEY).data ?? [],
  status: undefined
}

export const fetchFilmHistoryByRegistryIds = createAsyncThunk<
  FilmHistory[],
  number[],
  ThunkAPI
  >('filmHistory/fetchFilmHistoryByRegistryIds', async (ids, thunkAPI) => {
    if (window.USER_CONNECTED) {
      // user has data in session storage ?
      // we need to sync
      await postSessionStorage(SESSION_FILM_HISTORY_KEY, thunkAPI)

      const response = await apiV4Client.call({
        method: 'GET',
        url: '/watch_history/films',
        params: {
          registry_ids: ids.join(',')
        }
      })

      if (response.status !== 200) {
        throw new Error(`Response status: ${response.status}`)
      }

      return await response.data.data
    } else {
      // get from session storage
      const sessionHistory = fetchAllFromSessionStorage(SESSION_FILM_HISTORY_KEY).data
      return sessionHistory
        .filter((f: FilmHistory) => ids.includes(f.registry_id))
    }
  })

export const filmHistorySlice = createSlice({
  name: 'filmHistory',
  initialState,
  reducers: {
    updateFilmHistoryList (state, action: PayloadAction<FilmHistory[]>) {
      state.list = uniqBy([
        ...action.payload, // uniqBy keep first occurence
        ...state.list
      ], 'registry_id')

      // Record in local storage
      if (!window.USER_CONNECTED) {
        window.sessionStorage.setItem(SESSION_FILM_HISTORY_KEY, JSON.stringify(state.list))
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFilmHistoryByRegistryIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchFilmHistoryByRegistryIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        filmHistorySlice.caseReducers.updateFilmHistoryList(state, action)
      })
      .addCase(fetchFilmHistoryByRegistryIds.rejected, state => {
        state.status = 'failed'
        // @todo: handle errors
      })
  },
  selectors: {
    selectFilmHistoryById: (state: FilmHistoryState, id: number) => {
      return state.list.find(item => item.registry_id === id)
    }
  }
})

export const {
  selectFilmHistoryById
} = filmHistorySlice.getSelectors()

export const {
  updateFilmHistoryList
} = filmHistorySlice.actions

export default filmHistorySlice.reducer
