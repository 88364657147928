import { type WorkDirector, type WorkCard } from '@src/js/redux/worksReducer'
import { getPlatformLanguageCode } from '@src/js/helpers/language'

/**
 * @param duration
 * @return
 * Duration formatted to XX h XX min, XX min or XX s.
 * If duration can't be converted to Number, return duration.
*/
export const formatDuration = (duration: string | number) => {
  const durationToNumber = Number(duration)

  if (isNaN(durationToNumber)) return duration

  if (durationToNumber < 60) {
    return `${duration} s`
  }

  const h = Math.floor(durationToNumber / 3600).toString()
  const m = Math.floor(durationToNumber % 3600 / 60).toString()

  return `${h !== '0' ? `${h} h` : ''} ${m !== '0' ? `${m} min` : ''}`
}

/**
 * @param dateString Coming soon date returned from Public API, expected format: 'YYYY-MM-DDT00:00:00'.
 * @returns Date in format 'Month Day' if date is successfully parsed, else null.
 */
export const formatComingSoonDate = (dateString: string): string | null => {
  const lang = getPlatformLanguageCode()
  const dateObject = new Date(dateString)

  if (isNaN(dateObject.getDate())) {
    return null
  }

  return dateObject.toLocaleString(lang, { month: 'long', day: 'numeric' })
}

/**
 *
 * @param directors
 * @returns directors formatted:
 * 'FirstName LastName',
 * or 'FirstName1 LastName1 & FirstName2 LastName2',
 * or 'FirstName1 LastName1, FirstName2 LastName2 & FirstName3 LastName3'
 */
export const formatDirectors = (directors: WorkDirector[]): string | null => {
  if (directors?.length > 0) {
    let directorsString = ''
    directors.forEach((d, index) => {
      let separator = ''

      if (directors.length > 1 && index > 0) {
        if (index === directors.length - 1) {
          separator = ` ${window.gettext('&')} `
        } else {
          separator = ', '
        }
      }

      directorsString += `${separator}${d.first_name} ${d.last_name}`
    })
    return directorsString
  }

  return null
}

/**
 * @param work
 * @return
 * Series info formatted to X seasons or X episodes.
*/
export const formatSeriesInfo = (work: WorkCard) => {
  if (work.seasons_count && work.seasons_count > 1) {
    return `${work.seasons_count} ${window.pgettext('Helper to format series info', 'seasons')}`
  } else {
    return `${work.episodes_count} ${window.pgettext('Helper to format series info', 'episodes')}`
  }
}

/**
 * @param work
 * @return
 * Collection info formatted to X editions or X films.
*/
export const formatCollectionInfo = (work: WorkCard) => {
  if (work.seasons_count && work.seasons_count > 1) {
    return `${work.seasons_count} ${window.pgettext('Helper to format collections info', 'editions')}`
  } else {
    return `${work.episodes_count} ${window.pgettext('Helper to format collections info', 'films')}`
  }
}

/**
 * @param work
 * @return
 * Format year range year_max-year_min or year.
*/
export const formatYearRange = (work: WorkCard) => {
  return work.year_min !== work.year_max ? `${work.year_min + '-' + work.year_max}` : work.year
}
