/**
 * Class NFBBookmarksCarousel.
 *
 * Extends class NFBWorkCarousel.
 */

import { customElement } from 'lit/decorators.js'

import { store } from '@src/js/redux/store'
import { GTM_LIST_CATEGORY } from '@src/js/constants'
import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'

import NFBWorkCarousel from '@src/js/wc/carousels/base/NFBWorkCarousel'
import { fetchBookmarksList, selectBookmarksIds } from '@src/js/redux/bookmarksReducer'

@customElement('nfb-bookmarks-carousel')
export default class NFBBookmarksCarousel extends NFBWorkCarousel {
  showSeeMoreCard: boolean = true
  carouselTitle: string = window.pgettext('My list carousel title', 'My list')
  _includeBookmarkButton = false

  getCarouselGTMAttributes () {
    return {
      ...super.getCarouselGTMAttributes(),
      nfb_list_category: GTM_LIST_CATEGORY.USER_LIST,
      nfb_list_id: GTM_LIST_CATEGORY.USER_LIST
    }
  }

  async getRegistryIds () {
    await store.dispatch(fetchBookmarksList())
    const state = store.getState()
    return selectBookmarksIds(state.bookmarks)
  }
}
